import React, { memo } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { NavLink } from 'react-router-dom';
import Icon from '../../Icon/Icon';
import ListItemText from '@mui/material/ListItemText';
import mova from 'mova';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { reservationSelectors } from 'state/ducks/reservation';
import { userSelectors } from 'state/ducks/user';
import { placeSelectors } from 'state/ducks/place';
import { billingSelectors } from 'state/ducks/billing';
import Loader from '../../Loader/Loader';
import { accessSelectors } from "state/ducks/access";

const t = mova.ns('components.Sidebar');

const StyledListItem = styled(ListItemButton, { shouldForwardProp: prop => prop !== 'red' })(({ theme, red }) => ({
  color: red ? theme.palette.secondary.main : theme.palette.typography.blurred,
  paddingLeft: 0,
  paddingRight: 0,
  borderRadius: theme.shape.borderRadiusSmall,
}));

const SidebarMenu = ({ close }) => {
  const newReservations = useSelector(reservationSelectors.getNewReservations());
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const isSuperAdmin = useSelector(accessSelectors.isSuperAdmin());
  const role = useSelector(userSelectors.getUserRole());
  const billingsStats = useSelector(billingSelectors.getBillingsStats());
  const showMenu = useSelector(accessSelectors.showMenu());

  if (!billingsStats) {
    return <Loader />
  }

  return (
    <List onClick={close}>
      {isSuperAdmin && (
        <StyledListItem
          component={NavLink}
          activeStyle={{ color: 'white' }}
          to={`${routes.superadmin.path}?path=places`}
          exact
        >
          <ListItemIcon>
            <Icon type='star' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>{t('superadmin')}</Typography>
          </ListItemText>
        </StyledListItem>
      )}
      <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.home.path} exact>
        <ListItemIcon>
          <Icon type='home' />
        </ListItemIcon>
        <ListItemText>
          <Typography variant='h4'>{t('home')}</Typography>
        </ListItemText>
      </StyledListItem>
      {['admin', 'owner', 'super'].includes(role) && (
        <StyledListItem
          component={NavLink}
          activeStyle={{ color: 'white' }}
          to={`${routes.reservations.path}?filter=REQUESTED`}
          exact
          red={newReservations > 0}
        >
          <ListItemIcon>
            <Icon type='list' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>
              {t('reservations')} ({newReservations || 0})
            </Typography>
          </ListItemText>
        </StyledListItem>
      )}
      {((['enterprise', 'pro'].includes(activePlace.billType) && ['owner', 'admin'].includes(role)) || ['super'].includes(role)) && (
        <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.timeline.path} exact>
          <ListItemIcon>
            <Icon type='timeline' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>{t('timeline')}</Typography>
          </ListItemText>
        </StyledListItem>
      )}
      {((['enterprise', 'pro'].includes(activePlace.billType) && ['owner', 'admin'].includes(role)) || ['super'].includes(role)) && (
        <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.halls.path} exact>
          <ListItemIcon>
            <Icon type='grid' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>{t('halls')}</Typography>
          </ListItemText>
        </StyledListItem>
      )}
      {['owner', 'admin', 'super'].includes(role) && (
        <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.clients.path} exact>
          <ListItemIcon>
            <Icon type='users' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>{t('clients')}</Typography>
          </ListItemText>
        </StyledListItem>
      )}
      {['owner', 'analyst', 'super'].includes(role) && (
        <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.channels.path}>
          <ListItemIcon>
            <Icon type='chart' color='white' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>{t('channels')}</Typography>
          </ListItemText>
        </StyledListItem>
      )}
      {(isSuperAdmin || showMenu) && (
        <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.menu.path}>
          <ListItemIcon>
            <Icon type='dishes' color='white' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>{t('menu')}</Typography>
          </ListItemText>
        </StyledListItem>
      )}
      <StyledListItem
        component={NavLink}
        activeStyle={{ color: 'white' }}
        to={`${routes.reviews.path}?path=google`}
        exact
      >
        <ListItemIcon>
          <Icon type='comment' color='white' />
        </ListItemIcon>
        <ListItemText>
          <Typography variant='h4'>{t('reviews')}</Typography>
        </ListItemText>
      </StyledListItem>
      {['owner', 'analyst', 'super'].includes(role) && (
        <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.statistics.path} exact>
          <ListItemIcon>
            <Icon type='statistics' color='white' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>{t('statistics')}</Typography>
          </ListItemText>
        </StyledListItem>
      )}
      {['owner', 'super'].includes(role) && activePlace.depositsActive && (
        <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.depositStats.path}>
          <ListItemIcon>
            <Icon type='incomingPayments' color='white' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>{t('incomingPayments')}</Typography>
          </ListItemText>
        </StyledListItem>
      )}
      {!billingsStats.emptyBillType && ['owner', 'super'].includes(role) && (
        <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.payment.path}>
          <ListItemIcon>
            <Icon type='dollar' color='white' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>{t('payment')}</Typography>
          </ListItemText>
        </StyledListItem>
      )}
      {process.env.NODE_ENV === 'development' && (
        <StyledListItem component={NavLink} activeStyle={{ color: 'white' }} to={routes.sandbox.path} exact>
          <ListItemIcon>
            <Icon type='star' color='white' />
          </ListItemIcon>
          <ListItemText>
            <Typography variant='h4'>Sandbox</Typography>
          </ListItemText>
        </StyledListItem>
      )}
    </List>
  );
};

export default memo(SidebarMenu);
