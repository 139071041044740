import { userSelectors } from "../user";
import { placeSelectors } from "../place";

const isSuperAdmin = () => state => userSelectors.getUserRole()(state) === "super";
const showMenu = () => state => {
  const activePlace = placeSelectors.getActivePlace()(state);
  return activePlace.billTypeExtra?.includes('menu') && activePlace.menuActive;
}

export default {
  isSuperAdmin,
  showMenu,
};
